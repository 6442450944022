import SystemHandler from 'actions/SystemHandler';
import { useAuth } from 'auth/useAuth';
import LocalStorage from 'classes/LocalStorage';
import Observer, { EVENTS } from 'classes/Observer';
import { IUser } from 'common/interfaces';
import { BusinessModelType } from 'common/types';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'reducers/Hooks';
import { PROGRAM_VIEWS } from 'utils/constants';
import { scrollTop } from 'utils/helpers';
import useMerchant from './useMerchant';

export const useBusinessModel = () => {
  const [selectedBusinessModel, setSelectedBusinessModel] = useState<BusinessModelType>(LocalStorage.get<string>("business_model") as BusinessModelType);
  const businessModelUpdated = Observer.useObserver(EVENTS.BUSINESS_MODEL_UPDATED);
  const merchant = useMerchant();
  const partner = useAppSelector(state => state.partner);
  const whitelabel = useAppSelector(state => state.whitelabel);
  const user = useAuth()?.user;

  // set default businessModel in case it's not set
  useEffect(() => {
    if (selectedBusinessModel === null && user) {
      if (user?.user_type === "MERCHANT") {
        setProgramView(merchant?.programs);
      } else if (user?.user_type === "WHITELABEL") {
        setProgramView(whitelabel?.programs);
      } else if (user?.user_type === "PARTNER") {
        setProgramView(partner?.programs);
      }
    }
  }, [selectedBusinessModel, user]);

  const setProgramView = (programs) => {
    if ((user?.program_view === null && (programs as Array<string>).includes("DTC")) || user?.program_view === PROGRAM_VIEWS.HIDE_DTM || user?.program_view === PROGRAM_VIEWS.SHOW_ALL_DTC_DEFAULT) {
      setSelectedBusinessModel("DTC");
      LocalStorage.save("business_model", "DTC");
      Observer.trigger(EVENTS.BUSINESS_MODEL_UPDATED);
    }
    else if ((user?.program_view === null && (programs as Array<string>).includes("DTM")) || user?.program_view === PROGRAM_VIEWS.HIDE_DTC || user?.program_view === PROGRAM_VIEWS.SHOW_ALL_DTM_DEFAULT) {
      setSelectedBusinessModel("DTM");
      LocalStorage.save("business_model", "DTM");
      Observer.trigger(EVENTS.BUSINESS_MODEL_UPDATED);
    }
  }

  useEffect(() => {
    setSelectedBusinessModel(LocalStorage.get<string>("business_model") as BusinessModelType);
  }, [businessModelUpdated]);

  return selectedBusinessModel;
};